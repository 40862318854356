import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './assets/styles/_colors.scss';
import './assets/styles/bootstrap.css';
import './assets/styles/custom.scss';
import './assets/styles/multi-select.css';
import './assets/styles/ubbe.scss';
import MainLoader from './components/global-components/loader';
import LoggedInNavbar from './components/navbar/loggedInNavbar';
import SideBar from './components/navbar/sideBar';
import { RootState } from './redux/rootReducer';
import { logout } from './redux/app/appActions';
import NewAlert from './components/global-components/newAlert';

const MainPage = lazy(() => import('./main'));

function App() {
  const [openSideBar, setOpenSideBar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const expiredTime = localStorage.getItem('accessExpiry');
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { userData, userToken, hasSeenTerms } = useSelector(
    (state: RootState) => state.mainAppData
  );

  const isTermsAgreed = userData?.is_terms_agreed;
  const authenticatedUrls = location.pathname.includes('app/');
  const loginScreen = location.pathname === '/login';
  const resetPassword = location.pathname === '/reset_password';

  const isTokenExpired = () => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return expiredTime && currentTimestamp > Number(expiredTime);
  };

  useEffect(() => {
    const publicRoutes = [
      '/login',
      '/sign-up',
      '/reset_password',
      '/confirm_email',
      '/password_reset',
    ];
    const allowForAllUsers = ['/reset_password', '/password_reset'];
    const isPublicRoute = publicRoutes.includes(location.pathname);
    const isAllowedForAll = allowForAllUsers.includes(location.pathname);

    const checkAuthStatus = async () => {
      if (!userToken || isTokenExpired()) {
        if (!isPublicRoute) {
          localStorage.removeItem('authToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessExpiry');
          dispatch(logout());
          navigate('/login');
        }
      } else {
        if (isPublicRoute && !isAllowedForAll) {
          navigate('/app/dashboard', { replace: true });
        } else if (loginScreen) {
          navigate('/app/dashboard', { replace: true });
          window.history.pushState(null, '', '/app/dashboard');
        }
      }
      setIsLoading(false);
    };

    checkAuthStatus();
  }, [userToken, location.pathname, navigate]);

  useEffect(() => {
    if (!userToken || isTokenExpired() || isLoading) return;

    if (location.pathname === '/') {
      if (isTermsAgreed || hasSeenTerms) {
        navigate('/app/home');
      } else {
        navigate('/app/terms_agreed');
      }
    }
  }, [
    userToken,
    isTermsAgreed,
    hasSeenTerms,
    location.pathname,
    navigate,
    isLoading,
  ]);

  if (isLoading) return <MainLoader />;

  return (
    <Suspense fallback={<MainLoader />}>
      <div className='d-flex flex-column min-vh-100'>
        <div
          className={`d-flex flex-column flex-grow-1 ${
            location.pathname === '/' ? 'landing' : !loginScreen ? 'app-bg' : ''
          }`}
        >
          {!authenticatedUrls && !loginScreen && !resetPassword ? (
            <></>
          ) : !loginScreen && !resetPassword ? (
            <LoggedInNavbar setOpenSideBar={setOpenSideBar} />
          ) : null}
          {authenticatedUrls && (isTermsAgreed || hasSeenTerms) && (
            <SideBar setOpenSideBar={setOpenSideBar} />
          )}
          <div className={authenticatedUrls ? 'main-page' : ''}>
            <MainPage />
          </div>
        </div>
      </div>
    </Suspense>
  );
}

export default App;
